import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);


document.addEventListener("DOMContentLoaded", function (evt) {
  const apps = document.querySelectorAll(".sb-drsk-app__container--update-me");

  for (const app of apps) {
    let data = {};
    app.classList.remove("sb-drsk-app__container--update-me")

    try {
      data = JSON.parse(app.querySelector('pre').innerHTML);
    } catch (err) {
      console.warn(err);
    }

    const root = ReactDOM.createRoot(app);

    root.render(<App wpPlugin={data} />);
  };
});