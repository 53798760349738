import { Canvas, useFrame } from '@react-three/fiber';
import './App.css';
import TestScene from './Components/TestScene';
import { useRef } from 'react';
import { Box, OrbitControls, PerspectiveCamera, Sphere, View } from '@react-three/drei';
import { ScrollTrigger } from 'gsap/all';
import { useControls } from 'leva';
import { MeshStandardMaterial } from 'three';

function SyncCanvasLoc({ target = {} }) {
  useFrame((state, delta, xFrame) => {
    const transform = `translateY(${window.scrollY}px)`;
    target.current.style.transform = transform;
  });
}

function App() {

  const trackRef1 = useRef();
  const trackRef2 = useRef();
  const trackRef3 = useRef();
  const vizContainerRef = useRef();
  trackRef1.current = document.getElementById("track-1");
  trackRef2.current = document.getElementById("track-2");
  trackRef3.current = document.getElementById("track-3");


  const { normalize, sbSyncCanvas } = useControls({
    sbSyncCanvas: {
      value: false,
      transient: false,
      onChange: (v) => {
        if (!v) {
          vizContainerRef.current.style.position = "fixed";
          vizContainerRef.current.style.transform = "";
        } else {
          vizContainerRef.current.style.position = "absolute";
        }
        return v;
      }
    },
    normalizeScroll: {
      value: false,
      onChange: (v) => {
        ScrollTrigger.normalizeScroll(v);
      }
    }
  });


  return (
    <div className='sb-drsk__app-inner-container'>
      <div ref={vizContainerRef} className="sb-drsk__viz-container" >
        <Canvas >
          {sbSyncCanvas && <SyncCanvasLoc target={vizContainerRef}></SyncCanvasLoc>}
          <View
            track={trackRef1}>
            <color args={["#00ff00"]} attach={"background"}></color>
            <OrbitControls makeDefault></OrbitControls>
            <Box position={[0, 0, 0]}></Box>
          </View>
          <View
            track={trackRef2}>
            <color args={["#ff0000"]} attach={"background"}></color>
            <Sphere position={[0, 0, -3]}></Sphere>
            <PerspectiveCamera makeDefault></PerspectiveCamera>
            <OrbitControls makeDefault></OrbitControls>
          </View>
          <View
            track={trackRef3}>
            <Sphere position={[0, 0, -3]}>
              <meshBasicMaterial color="red"></meshBasicMaterial>
            </Sphere>
            <PerspectiveCamera makeDefault></PerspectiveCamera>
            <OrbitControls makeDefault></OrbitControls>
          </View>
        </Canvas>
      </div>
    </div>
  );
}

export default App;